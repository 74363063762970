import { Button, PageHeader } from "@reusable";
import React from "react";
import Base from "../layouts/base";

const NotFoundPage = () => (
  <Base>
    <PageHeader
      title="404 - Page not found"
      briefTitle="Page not found"
    ></PageHeader>
    <div className="container center">
      <Button as="a" to="/">
        Return to the homepage
      </Button>
    </div>
  </Base>
);

export default NotFoundPage;
